
::v-deep .el-dialog__header::after {
    content: '';
    width: 100%;
    height: 10px;
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid #EEEEEE;
}

.enter-box {
    display: flex;
    padding: 0 40px;
    align-items: center;
    height: 150px;

    .box-left {

    }

    .box-right {
        margin-left: 13px;
        width: 400px;
    }
}
